html, body {
  height: 100%;
  background-color: #0284c7;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#header {
  height: 6%;
}

#body {
 height: 94%; 
}

.react-calendar__month-view__days__day--weekend{
  color: #0077ff;
}

@media only screen and (max-width: 1366px) {
  #header {
    height: 7%;
  }
  
  #body {
   height: 93%; 
  }
}

@media only screen and (height: 900px) {
}

@media only screen and (height: 768px) {
}
